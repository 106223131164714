import React, { useState } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import "./ContactInformation.css"

import Marker from "../../images/placeholder.svg";
import Email from "../../images/email.svg";
import Tele from "../../images/phone-call.svg";

function ContactInformation(props) {
    const data = props.data;


    return (
        <div className="py-12 ci-tab px-8">
            <h2 className="ci-h2">{data.informationTitle}</h2>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell" style={{ width: "32px" }}>
                    <img src={Marker} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={data.address.addressGoogleLink} className="ci-links table-cell align-middle">{data.address.addressLabel}</a>
            </div>
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Tele} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`tel:${data.telephoneNumber}`} className="ci-links table-cell align-middle">{data.telephoneNumber}</a>
            </div>
            {/* <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Tele} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`tel:${data.mobileNumber}`} className="ci-links table-cell align-middle">{data.mobileNumber}</a>
            </div> */}
            <div className="pb-4 w-full text-left justify-start pt-8" >
                <div className="table-cell">
                    <img src={Email} style={{ width: "32px" }} alt="marker" />
                </div>
                <a href={`mailto:${data.supportEmail}`} className="ci-links table-cell align-middle">{data.supportEmail}</a>
            </div>
        </div>
    )
}

export default ContactInformation;